let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
let indentToViewport = 0;

if(document.querySelector('.docs-slider')) {
    indentToViewport  = window.innerWidth - document.querySelector('.docs-slider').getBoundingClientRect().right;
    document.documentElement.style.setProperty("--indentToViewport", `${indentToViewport}px`);
}

function debaunce(fn, ms) {
    let timer;
 
    return () => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            timer = null;
            fn.apply(this, arguments);
        }, ms)
    }
}



const debauncehandleResize = debaunce(function handleResize() {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    if(document.querySelector('.docs-slider')) {
        indentToViewport = window.innerWidth - document.querySelector('.docs-slider').getBoundingClientRect().right;
        document.documentElement.style.setProperty("--indentToViewport", `${indentToViewport}px`);
    }
}
, 300);


window.addEventListener('resize', debauncehandleResize);



function collapseBradcrumbs() {
    console.log('is work')
    const bredcrumbs = document.querySelector('.bredcrumbs'); 

    if(bredcrumbs) {
        const windowWidth = window.innerWidth;
        const bradcrumbsContainerWidth = bredcrumbs.clientWidth;
        const bradcrumbsItemsWidthSum = [...bredcrumbs.querySelectorAll('a')].reduce((a, b) => {
            a = a +  b.offsetWidth;
            return a;
        }, 0);

        if(windowWidth > 577 && windowWidth <= 768) {
            if(bradcrumbsContainerWidth < bradcrumbsItemsWidthSum) {
                bredcrumbs.classList.add('collapsed');
            }

            return;
        } 

        if(windowWidth <= 577) {
            if(bradcrumbsContainerWidth * 2 < bradcrumbsItemsWidthSum ) {
                bredcrumbs.classList.add('collapsed');
            }

            return;
        }

        bredcrumbs.classList.remove('collapsed');
        
    }
    
}

const debauncehandleBradcrumbs = debaunce(collapseBradcrumbs
, 200);


debauncehandleBradcrumbs();
window.addEventListener('resize', debauncehandleBradcrumbs);

function handlePaginationControl(instanse, sliderCls) {
    instanse.on('slideChange', function() {
        console.log('cahnge')
        let index = instanse.activeIndex;

        $(`${sliderCls} .swiper-pagination .swiper-pagination-bullet`).removeClass('swiper-pagination-bullet-active');
        $(`${sliderCls}  .swiper-pagination .swiper-pagination-bullet`).eq(index).addClass('swiper-pagination-bullet-active');
    });
}

$(document).ready(function() {

    (function() {
         
        if($('#js-ymaps').length) {
            ymaps.ready(init);

            function init () {

                const map = new ymaps.Map("map", {
                    
                    center: [59.964476, 30.338362],
                    zoom: 16.4,
                    controls: []
                    }, {
                    searchControlProvider: 'yandex#search'
                    }),
                    myPlacemark = new ymaps.Placemark(map.getCenter(), {
                            hintContent: 'Адрес магазина',
                            balloonContent: 'Адрес магазина'
                    }, {
                            iconLayout: 'default#image',
                            iconImageHref: 'img/svg/loc.svg',
                            iconImageSize: [80, 80],
                            iconImageOffset: [-15, -32]
                    });

                    map.geoObjects.add(myPlacemark); 


                    let pixelCenter = map.getGlobalPixelCenter();

                    pixelCenter = [
                        pixelCenter[0] - 200,
                        pixelCenter[1]
                    ];

                    const geoCenter = map.options.get('projection').fromGlobalPixels(pixelCenter, map.getZoom());

                    map.setCenter(geoCenter);

                    map.behaviors.disable('scrollZoom');

                    if($(window).width() <= 768) {

                        

                        pixelCenter = [
                            pixelCenter[0],
                            pixelCenter[1]
                        ];

                        const geoCenter = map.options.get('projection').fromGlobalPixels(pixelCenter, map.getZoom());

                        map.setCenter(geoCenter);

                        map.setBounds(map.geoObjects.getBounds(),{checkZoomRange: false, zoomMargin: 40});

                        map.setZoom(15);
                    }
                } 
            }

     })();


    if($('.main-slider').length) { 
        const mainSlider = new Swiper('.main-slider', {
            speed: 1000,
            spaceBetween: 10,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },

            navigation: {
                nextEl: '.main-slider .next',
                prevEl: '.main-slider .prev',
            },
        
        });

        handlePaginationControl(mainSlider, '.main-slider');

    }


    if($('.reviews-slider').length) { 
        const reviewsSlider = new Swiper('.reviews-slider', {
            speed: 1000,
            spaceBetween: 80,
            loop: true,

            navigation: {
                nextEl: '.reviews-slider .sld-nav-btn-next',
                prevEl: '.reviews-slider .sld-nav-btn-prev',
            },

            pagination: {
                el: '.reviews-slider .swiper-pagination',
                clickable: true
            },

            breakpoints: {
                320: {
                    spaceBetween: 30,
                },

                1025: {
                    spaceBetween: 80,
                },
            }
        
        });

        handlePaginationControl(reviewsSlider, '.reviews-slider');
        
    }

    if($('.swiper-gallery').length) { 
        var galleryThumbs = new Swiper(".swiper-thumbs", {
            spaceBetween: 10,
            slidesPerView: 6,
            freeMode: true,
            watchSlidesProgress: true,

            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                },

                578: {
                    slidesPerView: 'auto',
                    direction: 'vertical',
                },

                769: {
                    slidesPerView: 5,
                    direction: 'horizontal',
                },

                1025: {
                    slidesPerView: 6,
                },
            }
        });


        var gallery = new Swiper(".swiper-gallery", {
            spaceBetween: 10,
            speed: 1000,
            thumbs: {
                swiper: galleryThumbs,
            },
        });
    }

    if($('.docs-slider').length) {
        const docsSlider = new Swiper('.docs-slider', {
            speed: 1000,
            spaceBetween: 30,
            slidesPerView: 'auto',
            
            navigation: {
                nextEl: '.docs-slider .sld-nav-btn-next',
                prevEl: '.docs-slider .sld-nav-btn-prev',
            },

            breakpoints: {
      
                577: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    direction: 'horizontal',
                },

                1025: {
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                },
            }
        
        });
    }


    



     $('.input-wrap input, .input-wrap textarea').on('focus', function() {
        const parents = $(this).parents('.input-wrap');

        parents.addClass('active');
     });

     $('.input-wrap input, .input-wrap textarea').on('focusout', function() {
        const parents = $(this).parents('.input-wrap');

        if($(this).val() === '') {
            parents.removeClass('active');
        }
     });


     $('.menu-btn').on('click', function(e) {
        e.preventDefault();
        $('html').toggleClass('no-scroll');
        $('.overlay').toggleClass('active');
        $(this).toggleClass('active');
        $('.header-nav-row').toggleClass('active');
     });

     $('.overlay').on('click', function(e) {
        e.preventDefault();
        $('html').removeClass('no-scroll');
        $('.overlay').removeClass('active');
        $(this).removeClass('active');
        $('.header-nav-row').removeClass('active');
        $('.modal').removeClass('active');
     });


     $('.has-submenu  > a').on("click", function(e) {
        e.preventDefault();

        $(this).parents('li.has-submenu').toggleClass('active');
        $(this).siblings('ul').slideToggle(300);
     });   
     
     $('a.js-toggle-modal[href^="#"]').on('click', function(e) {
        e.preventDefault();
        let id = $(this).attr('href');
        $(`.modal${id}`).addClass('active');
     });


     $('.modal-close, .modal-overlay').on('click', function(e) {
        e.preventDefault();
        $('.modal').removeClass('active');
     });


    
    



    // var Position = {
    //     ellipse : function(n, rx, ry, so, wh, idd, cls, cw) {
    //       var m = document.createElement('div');
    //       var ss = document.styleSheets;

    //     //   ss[0].insertRule('#' + idd + ' { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); border-radius: 50%; box-shadow: inset 0 0 ' + wh + 'px ' + wh/4 +'px black; background: rgba(0, 0, 0, 0.2); width: ' + String((rx * 2) + wh) + 'px; height: ' + String((ry * 2) + wh) + 'px; }', 1);
    //     //   ss[0].insertRule('.' + cls + '{ position: absolute; background: black; color: papayawhip; text-align: center; font-family: "Open Sans Condensed", sans-serif; border-radius: 50%; transition: transform 0.2s ease; width: ' + wh + 'px; height: ' + wh + 'px; line-height: ' + wh + 'px;}', 1);
    //     //   ss[0].insertRule('.' + cls + ':hover { transform: scale(1.2); cursor: pointer; background: rgba(0, 0, 0, 0.8); }', 1);
    //       m = document.getElementById(idd);

    //       for (var i = 0; i < n; i++) {
    //         var c = document.createElement('div');
    //         c.className = cls;
    //         c.innerHTML = i + 1;
    //         c.style.top = String(ry + -ry * Math.cos((360 / n / 180) * (i + so) * Math.PI)) + 'px';
    //         c.style.left = String(rx + rx * (cw ? Math.sin((360 / n / 180) * (i + so) * Math.PI) : -Math.sin((360 / n / 180) * (i + so) * Math.PI))) + 'px';
    //         m.appendChild(c);
    //       }
    //     }
    //   }
      
    //   Position.ellipse(8, 295, 295, 0, 0, 'circle', 'circle', true);

})


let featuresSlider = null;
let procedureSlider = null;
let jobsTypeSlider = null;

$(window).on('load resize', function() {
    if($('.features-slider').length) {
        

        if($(window).width() <= 577) {
            featuresSlider = new Swiper('.features-slider', {
                speed: 1000,
                spaceBetween: 20,
                grid: {
                    rows: 2,
                },
                
                pagination: {
                    el: '.features-slider .swiper-pagination',
                    clickable: true
                },
    
            });


            handlePaginationControl(featuresSlider, '.features-slider');
        } else {
          
            featuresSlider ? featuresSlider.destroy(true, true) : null;
        }

        

    }

    
    if($('.procedure-slider').length) {
        
        console.log('.procedure-slider')

        if($(window).width() <= 577) {
            procedureSlider = new Swiper('.procedure-slider', {
                speed: 1000,
                spaceBetween: 20,
                autoHeight: true,
                
                pagination: {
                    el: '.procedure-slider .swiper-pagination',
                    clickable: true
                },
    
            });

            handlePaginationControl(procedureSlider, '.procedure-slider');
            
        } else {
            procedureSlider ? procedureSlider.destroy(true, true) : null;
        }

    }

    if($('.jobs-type-list-slider').length) {
        console.log('.jobs-type-list-slider')

        if($(window).width() <= 577) {
            jobsTypeSlider = new Swiper('.jobs-type-list-slider', {
                speed: 1000,
                spaceBetween: 20,
                autoHeight: true,
                
                pagination: {
                    el: '.jobs-type-list-slider .swiper-pagination',
                    clickable: true
                },
    
            });

            handlePaginationControl(jobsTypeSlider, '.jobs-type-list-slider');
            
        } else {
            jobsTypeSlider ? jobsTypeSlider.destroy(true, true) : null;
        }
    }
})